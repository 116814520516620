<template>
  <FormModal show-back-btn next-btn-text="Volgende">
    <template #body>
      <h1 class="form-modal__title">
        {{ $t('title') }}
      </h1>
      <p class="form-modal__description">
        {{ $t('description') }}
      </p>
      <AddressInformationFormStep ref="step" :progress-value="progressValue" />
    </template>
  </FormModal>
</template>

<script>
import FormModal from 'chimera/all/themes/mars/components/layout/FormModal'
import AbstractFormPage from 'chimera/all/components/page/AbstractFormPage'
import AddressInformationFormStep from '~/components/form/steps/addressInformation/AddressInformationFormStep'

export default {
  components: {
    AddressInformationFormStep,
    FormModal,
  },

  extends: AbstractFormPage,

  layout: 'form',

  /**
   * @returns {object}
   */
  data() {
    return {
      headTitle: 'Adresgegevens',
      path: '/offertes-aanvragen/adresgegevens',
      progressValue: 50,
      checkoutStep: 3,
    }
  },
}
</script>

<i18n>
{
  "nl-NL": {
    "title": "Wat is je locatie?",
    "description": "Wij selecteren de beste rijscholen bij jou in de buurt."
  }
}
</i18n>
