<template>
  <div>
    <t-form ref="form" @submit.prevent>
      <AddressFormGroup @result="onResult" @change="onChange" />
    </t-form>
  </div>
</template>

<script>
import AbstractFormStep from 'chimera/all/components/form/steps/AbstractFormStep'
import AddressFormGroup from 'chimera/all/themes/mars/components/form/part/address/AddressFormGroup'
import NextStep from '~/pages/offertes-aanvragen/je-gegevens'

export default {
  name: 'AddressInformationFormStep',

  components: {
    AddressFormGroup,
  },

  extends: AbstractFormStep,

  data: () => ({
    willTransitionOnValid: true,
  }),

  /**
   * Pass through the submit event given from FormModal
   */
  created() {
    this.$nuxt.$on('submit', this.onSubmit)
  },

  /**
   * Clean up before component is destroyed
   */
  destroyed() {
    this.$nuxt.$off('submit')
  },

  methods: {
    /**
     * Transition
     */
    transition() {
      this.routeTo(NextStep)
    },
  },
}
</script>
