<template>
  <section>
    <div class="form-row">
      <AddressPostalRadioField
        :label="'Jouw postcode*'"
        placeholder=""
        :init-with-store-key="initPostalWithStoreKey"
        @result="onResult"
        v-on="$listeners"
      />
    </div>

    <div class="form-row">
      <AddressCityField
        ref="addressCity"
        :label="'Jouw woonplaats*'"
        placeholder=""
        autocomplete="no-fill"
        @result="onResult"
        v-on="$listeners"
      />
    </div>

    <div class="form-row">
      <AddressStreetField
        ref="addressStreet"
        :label="'Jouw straat*'"
        placeholder=""
        autocomplete="no-fill"
        @result="onResult"
        v-on="$listeners"
      />
    </div>

    <div class="form-row">
      <AddressNumberField
        :label="'Jouw huisnummer*'"
        placeholder=""
        @result="onResult"
        v-on="$listeners"
      />
    </div>
  </section>
</template>

<script>
import AddressFormGroup from 'chimera/all/components/form/parts/address/AddressFormGroup'
import AddressPostalRadioField from 'chimera/all/components/form/fields/addressPostal/AddressPostalRadioField'
import AddressNumberField from 'chimera/all/components/form/fields/addressNumber/AddressNumberField'
import AddressStreetField from 'chimera/all/components/form/fields/addressStreet/AddressStreetField'
import AddressCityField from 'chimera/all/components/form/fields/addressCity/AddressCityField'

export default {
  name: 'AddressFormGroup',

  components: {
    AddressPostalRadioField,
    AddressNumberField,
    AddressStreetField,
    AddressCityField,
  },

  extends: AddressFormGroup,
}
</script>
